@import '~normalize.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/popover2/lib/css/blueprint-popover2.css';
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";

@import 'variables';

// ---------------------------------------

*,
:after,
:before
{
  box-sizing: inherit;
}

:root
{
  --app-height: 100%;
}

html,
body
{
  height: 100%;
}

html
{
  box-sizing: border-box;
}

body
{
  margin: 0;
  padding: 0;

  color: @colorText;
  background: #fff;

  font-family: 'Roboto', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// ---------------------------------------

h1
{
  margin: 10px 0;
  padding-bottom: 20px;

  text-align: center;

  border-bottom: 1px solid #aaa;
}

// ---------------------------------------

.debugFrame()
{
  box-shadow: 0 0 0 1px rgba(255, 0, 0, 0.5) inset;
}

// ---------------------------------------

.todo,
.TODO
{
  position: relative;

  background: rgba(255, 255, 0, 0.5);

  .debugFrame;

  &:before
  {
    position: absolute;
    z-index: 99999;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    .debugFrame;
  }

  &:after
  {
    position: absolute;
    z-index: 99999;
    top: -12px;
    left: -12px;

    width: 24px;
    height: 24px;

    content: '?';
    text-align: center;

    color: #ff0;
    border-radius: 50%;
    background: rgba(255, 0, 0, 0.7);
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.5);

    font-size: 20px;
    line-height: 24px;
  }
}

// ---------------------------------------

.bp3-popover2 .bp3-popover2-content
{
  border-radius: inherit; // fix
}

.bp3-portal,
.bp4-portal
{
  z-index: @zindexBPPortal;
}

.bp3-portal
{
  position: relative; // popover 2
}

.bp4-button.bp4-outlined.bp4-intent-primary
{
  //border-color: rgba(33, 93, 176, 0.6); // BP
  border: 1px solid #2965CC;
}

// ---------------------------------------

/* When mouse is detected, ALL focused elements have outline removed. */
body.using-mouse :focus, body.using-mouse :focus-visible, body.using-mouse :focus
{
  outline: none !important;
}

body.using-mouse input:focus + .bp4-control-indicator
{
  outline: none !important;
}

body.using-mouse textarea:focus
{
  box-shadow: 0 0 0 1px @colorGray20 inset;
}

a
{
  text-decoration: none;
  color: @colorLink;

  &:hover
  {
    color: @colorLink;
  }
}

// ---------------------------------------

.move-up-bg-exit-active,
.move-up-exit-active,
.move-down-bg-exit-active,
.move-down-exit-active,
.move-up-bg-enter-active,
.move-up-enter-active,
.move-down-bg-enter-active,
.move-down-enter-active
{
  pointer-events: none;

  transition: transform 150ms linear;
}

@deltaX: 10px;

// ---------------------------------------

.move-up-bg-exit,
.move-up-bg-enter,
.move-down-bg-exit,
.move-down-bg-enter
{
  position: relative;
  z-index: 1;

  border-color: transparent !important;
  //background: #e1e8ed !important;

  // from figma -> filter: drop-shadow
  // filter: drop-shadow(0px 0px 0px @colorGray10) drop-shadow(0px 5px 20px @colorGray20);
  box-shadow: 0 0 0 @colorGray10, 0 5px 20px @colorGray20;
}

.move-up-exit,
.move-up-bg-exit
{
  transform: translateY(100%) translateX(0px);
}

.move-up-exit-active
{
  transform: translateY(50%);
}

.move-up-bg-exit-active
{
  transform: translateY(50%) translateX(@deltaX);
}

.move-up-enter
{
  transform: translateY(50%);
}

.move-up-bg-enter
{
  transform: translateY(50%) translateX(@deltaX);
}

.move-up-enter-active,
.move-up-bg-enter-active
{
  transform: translateY(0%) translateX(0px);
}

// ---------------------------------------

.move-down-exit,
.move-down-bg-exit
{
  transform: translateY(-100%) translateX(0px);
}

.move-down-exit-active
{
  transform: translateY(-50%);
}

.move-down-bg-exit-active
{
  transform: translateY(-50%) translateX(@deltaX);
}

.move-down-enter
{
  transform: translateY(-50%);
}

.move-down-bg-enter
{
  transform: translateY(-50%) translateX(@deltaX);
}

.move-down-enter-active,
.move-down-bg-enter-active
{
  transform: translateY(0%) translateX(0px);
}

// ---------------------------------------
